import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";

const ManageJobFormPagination = ({
  jobData,
  onPageChange,
  columnsPerPage,
  count,
  jobNextData,
  dataFromSelection,
  cursorData,
  manageJobPaginationError,
  handleNextList,
  handlePrevList,
  currentPage,
  cursor,
  jobs,
}) => {
  const devBase = localDevBase();
  const token = useAuthToken();

  console.log(currentPage, count);
  const [pageIndex, setPageIndex] = useState(1);
  const cursorRef = useRef(cursorData);
  console.log(cursorData);
  const totalPages = Math.ceil(count / columnsPerPage);
  const code = useRoleKey();

  useEffect(() => {
    setPageIndex(currentPage);
  }, [currentPage]);

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const startIndex = (pageIndex - 1) * columnsPerPage + 1;
  let endIndex;

  if (startIndex < 9) {
    endIndex = Math.min(startIndex + columnsPerPage - 1, count);
  } else {
    endIndex = Math.min(startIndex + columnsPerPage - 1);
  }

  useEffect(() => {
    console.log("MY CURSOR ", cursorData);
  }, [cursor, currentPage, jobs]);

  return (
    <div className="d-flex">
      <div className="align-middle">
        <small>
          Showing {startIndex} to {endIndex} of {count} entries
        </small>
      </div>
      <ButtonToolbar
        aria-label="Toolbar with button groups"
        className="ms-auto"
      >
        <Button
          className="me-2"
          disabled={currentPage === 1}
          onClick={() => handlePrevList(currentPage - 1)}
        >
          {/* {currentPage} */}
          Previous
        </Button>
        <ButtonGroup className="me-2" aria-label="Second group">
          <Button style={{ cursor: "text" }}>{currentPage}</Button>
        </ButtonGroup>
        {count >= 10 ? (
          <>
            <Button
              disabled={!cursorData}
              onClick={() => handleNextList(currentPage + 1)}
            >
              Next
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={!cursorData}
              onClick={() => handleNextList(currentPage + 1)}
            >
              Next
            </Button>
          </>
        )}
      </ButtonToolbar>
    </div>
  );
};

export default ManageJobFormPagination;
