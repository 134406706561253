import React, { useEffect, useState } from "react";
import CreateAccount from "../ManualEmail/CreateAccount";
import Google from "../../../../../layouts/LoginRegSSOIcon/SocialMediaButtons/Google";
import Facebook from "../../../../../layouts/LoginRegSSOIcon/SocialMediaButtons/Facebook";
import InputEmail from "../FinalStep/InputEmail";
import ChooseUserType from "../FinalStep/ChooseUserType";
import ConfirmEmail from "../FinalStep/ConfirmEmail";
import ReferralInformation from "../FinalStep/ReferralInformation";
import "../../../../../assets/scss/Components/UI/_ssoIcon.scss";
import Step3 from "../ManualEmail/Step3";
import CustomModal from "../../../../../components/UI/Modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import SSO from "../../../../../layouts/LoginRegSSOIcon/SocialMediaButtons/sample";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import GoogleBtn from "../../../../../layouts/LoginRegSSOIcon/SocialMediaBtnsWithoutFunc/GoogleBtn";
import FacebookBtn from "../../../../../layouts/LoginRegSSOIcon/SocialMediaBtnsWithoutFunc/FacebookBtn";
const ContainerIndex = () => {
  const [errorMessage, setErrorMsg] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState(""); // State to store the email
  const [id, setId] = useState("");
  const [refId, setRefId] = useState("");
  const [userType, setUserType] = useState("");
  const [reg_type, setRegType] = useState("");
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(null);
  const [privacyPolicyButtonClicked, setprivacyPolicyButtonClicked] =
    useState(false);
  const [signUpData, setSignUpData] = useState({});

  useEffect(() => {
    console.log("isPrivacyPolicyChecked changed:", isPrivacyPolicyChecked);
  }, [isPrivacyPolicyChecked]);

  useEffect(() => {
    // Check if there's existing data in localStorage for the initial setup
    const storedStep = localStorage.getItem("currentStep");
    const storedRefId = localStorage.getItem("ref_id");
    const storedUserType = localStorage.getItem("userType");

    if (storedStep) {
      setCurrentStep(Number(storedStep)); // Convert the stored step to a number
    }
    if (storedRefId) {
      setRefId(storedRefId);
    }
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []); // Empty dependency array to run only once when the component mounts

  useEffect(() => {
    if (
      currentStep > 3 &&
      window.location.pathname === "/talent/registration"
    ) {
      console.log("MY ID", refId);
      localStorage.setItem("ref_id", refId || "");
      localStorage.setItem("userType", userType || "");
      localStorage.setItem("currentStep", currentStep);
    }
  }, [currentStep, id, userType]);

  const handleSSOError = (error) => {
    setErrorMsg(error);
    setModalErrorShow(true);
  };
  // console.log(errorMessage);
  const handleModalErrorClose = () => {
    setModalErrorShow(false);
    if (
      errorMessage === "User already exists." ||
      errorMessage === "Failed to fetch data please reload page."
    ) {
      window.location.reload();
    }
  };

  const renderFormStep = () => {
    console.log(currentStep);
    if (currentStep === 1) {
      const handleModalErrorClose = () => {
        setModalErrorShow(false);
      };

      // Function to handle error message from GoogleLogin
      const handleSSOError = (error) => {
        setErrorMsg(error); // Set the error message
        setModalErrorShow(true); // Show the modal
      };

      const handlePrivacyPolicyCheck = (isChecked) => {
        setIsPrivacyPolicyChecked(isChecked);
      };

      return (
        <>
          <CreateAccount
            onSubmit={(email, id, reg_type) => {
              setRegType(reg_type);
              setCurrentStep(3);
              setEmail(email);
              setRefId(id);
            }}
            onPrivacyPolicyCheck={(isChecked) =>
              setIsPrivacyPolicyChecked(isChecked)
            }
          />
          <div className="separate">
            <hr />
            <p>or</p>
            <hr />
          </div>

          <div className="socialMediaIcons">
            <CustomModal
              show={modalErrorShow} // Show the modal when needed
              modalMessage={errorMessage} // Error message
              modalType="Warning" // Type of modal (you can customize this)
              buttonName="Close" // Button label
              onClick={() => handleModalErrorClose()}
            />
            {/* <SSO /> */}
            {isPrivacyPolicyChecked ? (
              <>
                <Google
                  onError={handleSSOError}
                  registration={true}
                  isPrivTermsChecked={isPrivacyPolicyChecked}
                  onSubmit={(email, id, googleId, reg_type) => {
                    setEmail(email);
                    setId(id);
                    setRefId(googleId);
                    setRegType(reg_type);
                    if (reg_type === "google") {
                      setCurrentStep(4);
                    } else {
                      setCurrentStep(3);
                    }
                    // You can also perform any other actions you need with this data
                  }}
                />
              </>
            ) : (
              <>
                <GoogleBtn
                  onPrivacyPolicyCheck={(isChecked) => {
                    if (isChecked === null || isChecked === false) {
                      setIsPrivacyPolicyChecked(false);
                      setprivacyPolicyButtonClicked(true);
                      setprivacyPolicyButtonClicked(false);
                    } else {
                      setIsPrivacyPolicyChecked(isChecked);
                    }
                  }}
                />
              </>
            )}

            <div id="fbContainerLogo">
              {isPrivacyPolicyChecked ? (
                <>
                  {" "}
                  <Facebook
                    onError={handleSSOError}
                    onSubmit={(email, id, fbId, reg_type) => {
                      setEmail(email);
                      setId(fbId);
                      setRefId(id);
                      setRegType(reg_type);
                      if (email === undefined) {
                        setCurrentStep(2); // Go to ConfirmEmail
                      } else {
                        setCurrentStep(4); // Go to ChooseUserType
                      }
                      // You can also perform any other actions you need with this data
                    }}
                  />
                </>
              ) : (
                <>
                  <FacebookBtn
                    onPrivacyPolicyCheck={(isChecked) => {
                      if (isChecked === null || isChecked === false) {
                        setIsPrivacyPolicyChecked(false);
                        setprivacyPolicyButtonClicked(true);
                      } else {
                        setIsPrivacyPolicyChecked(isChecked);
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>

          {isPrivacyPolicyChecked === false &&
            privacyPolicyButtonClicked === true && (
              <CustomMessage label="To create an account, you must agree to Privacy policy and Terms and Conditions." />
            )}
        </>
      );
    } else if (currentStep === 2) {
      return (
        <>
          <InputEmail
            id={id}
            onSubmit={(email) => {
              setEmail(email);
              setCurrentStep(3);
            }}
          />
        </>
      );
    } else if (currentStep === 3) {
      return (
        <ConfirmEmail
          id={id}
          email={email}
          reg_type={reg_type}
          onSubmit={() => setCurrentStep(4)}
        />
      );
    } else if (currentStep === 4) {
      return (
        <ChooseUserType
          userType={userType}
          onSubmit={(userType) => {
            setCurrentStep(5);
            setUserType(userType);
          }}
        />
      );
    } else if (currentStep === 5) {
      return (
        <ReferralInformation
          signUpData={setSignUpData}
          onError={handleSSOError}
          ref_id={refId}
          id={id}
          userType={userType}
          reg_type={reg_type}
          onSubmit={(step, backStep) => {
            setCurrentStep(step);
            {
              backStep ? setCurrentStep(backStep) : setCurrentStep(6);
            }

            setUserType(userType);
          }}
        />
      );
    } else if (currentStep === 6) {
      return <Step3 signUpData={signUpData} />;
    }
  };

  return (
    <div>
      <div className="containerIndex">
        <div className="content">{renderFormStep()}</div>
      </div>
      <CustomModal
        show={modalErrorShow} // Show the modal when needed
        color="red" // Color for the icon
        icon={faTimes} // Icon for error
        modalMessage={errorMessage}
        modalType="Warning"
        buttonName={
          errorMessage === "Failed to fetch data please reload page."
            ? "Reload"
            : "Close"
        }
        onClick={() => handleModalErrorClose()}
      />
    </div>
  );
};

export default ContainerIndex;
