import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import ManageJobFormPagination from "./ManageJobFormPagination";
import ManageJobFormTable from "./ManageJobFormTable";
import axios from "axios";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";
import CustomModal from "../../../../../components/UI/Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutAndClearProfile } from "../../../../../services/Actions/logoutAction";
import { handleAxiosError } from "../../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import { useMessageHandler } from "../../../../../hook/error/messageHandler";
import { Message } from "../../../../../Utilities/Reusable/Message/Message";
const ManageJobForm = () => {
  const {
    showModal,
    modalMessage,
    messageType,
    showLogoutButton,
    handleResponse,
    handleError,
    // handleLogout,
    closeModal,
  } = useMessageHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [allError, setAllError] = useState("");
  const token = useAuthToken();
  const devBase = localDevBase();
  const [jobs, setJobs] = useState([]);
  const [jobData, setJobData] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [jobNextDataList, setJobNextDataList] = useState("");
  const [dataNextFilter, setDataNextFilter] = useState("");
  const [cursor, setCursor] = useState();
  const [tableIndex, setTableIndex] = useState(0);
  const [cursorList, setCursorList] = useState([]);
  const [jobCursor, setJobCursor] = useState([]);
  const cursorRef = useRef(cursor);
  // const [cursorList, setCursorList] = useState([]);
  const cursorListRef = useRef([]);

  useEffect(() => {
    cursorListRef.current = cursorList;
  }, [cursorList]);

  const code = useRoleKey();
  const [pageError, setPageError] = useState(null);
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [errorNetwork, setErrorNetwork] = useState();
  const [modalInfo, setModalInfo] = useState({ show: false });

  // const fetchData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(`${devBase}/api/ats/manage-job`, {
  //       headers,
  //     });
  //     const result = response?.data?.result;
  //     const allJobs = result?.data;

  //     const filteredJobs = allJobs.filter(
  //       (job) =>
  //         job.jobTitle.toLowerCase().includes(searchKeyword.toLowerCase()) ||
  //         job.jobUUID.toLowerCase().includes(searchKeyword.toLowerCase())
  //     );

  //     console.log("HHHHHHHH", response);
  //     setJobData(response);

  //     setJobs(filteredJobs);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     if (error.response?.status >= 403) {
  //       handleAxiosError(error);
  //     } else if (error.message === "Network Error") {
  //       setErrorNetwork(error.message);
  //     } else {
  //       setPageError(error);
  //     } // handleAxiosError(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const [filter, setfilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inpKeyword, setInpKeyword] = useState("");
  const [startCount, setStartCount] = useState(1);
  const handleSearchAndSort = async (e, inpKeyword, filter = null) => {
    setfilter(filter);
    e?.preventDefault();
    setSearchKeyword(inpKeyword);
    setIsLoading(true);
    setCurrentPage(1);
    setCursorList([]);

    try {
      const params = {};
      if (filter) params.filter = filter;
      if (!filter || inpKeyword) params.keyWord = inpKeyword;

      const response = await axios.get(`${devBase}/api/ats/manage-job`, {
        params,
        headers,
      });

      const initialCursor = response?.data?.result?.cursor;
      const initialJobs = response?.data?.result?.data || [];

      setJobs(initialJobs);
      setJobData(initialJobs);
      setJobCursor(initialCursor);
      setCursor(initialCursor);

      if (initialCursor) {
        setCursorList([initialCursor]);
      } else {
        console.warn("Initial cursor is null or undefined.");
      }
      cursor(initialCursor);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errorNetwork === "Network Error") {
      setModalInfo({
        show: true,
        type: "Error",
        message: Message.client.switchUser[404],
        func: "draftJob",
        ErrButton: "Close",
        // bName: "Ok",
      });
    }
  }, [errorNetwork]);

  const handleDataNextFilter = (data) => {
    setDataNextFilter(data);
  };

  const reloadData = async () => {
    await handleSearchAndSort();
  };

  useEffect(() => {
    handleSearchAndSort();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columnsPerPage = 10;
  const startIndex = (currentPage - 1) * columnsPerPage;
  const endIndex = Math.min(startIndex + columnsPerPage, jobs.length);
  const visibleJobs = jobs.slice(startIndex, endIndex);

  const jobCount = jobs.length;

  const handleLogout = () => {
    setModalShow(false);
    setAllError("");
    dispatch(LogoutAndClearProfile());
    localStorage.clear();
    sessionStorage.clear();

    navigate("/");
  };

  const handleCursorData = (newData) => {
    setCursor(newData);
    cursorRef.current = newData;
  };

  const handleJobListData = (value) => {
    setJobNextDataList(value);
  };

  const handleGetError = (value) => {
    setPageError(value);
  };

  const handleNextList = async (page) => {
    const cursorForPage = cursorListRef.current[page - 2];

    if (!cursorForPage) {
      console.log("No cursor available for page:", page);
      return;
    }

    try {
      const response = await axios.get(`${devBase}/api/ats/manage-job`, {
        params: {
          cursor: cursorForPage,
          filter: dataNextFilter,
        },
        headers,
      });

      const newCursor = response?.data?.result?.cursor || null;
      const jobData = response?.data?.result?.data;

      setJobs((prevJobs) => {
        const updatedJobs = [...prevJobs, ...jobData];
        return updatedJobs;
      });
      setJobData(response?.data?.result?.data);

      if (newCursor && !cursorListRef.current.includes(newCursor)) {
        setCursorList((prev) => [...prev, newCursor]);
      }

      setJobCursor(newCursor);
      setCurrentPage(page);
      setCursor(newCursor);
    } catch (error) {
      console.error("Error fetching next page data:", error);
    }
  };

  const handlePrevList = async (page) => {
    const prevCursor = cursorListRef.current[page - 1];
    if (page === 1) {
      await handleSearchAndSort(null, searchKeyword, dataNextFilter);
      return;
    }

    if (!prevCursor) {
      console.log("No previous cursor available for page:", page);
      return;
    }

    try {
      const response = await axios.get(`${devBase}/api/ats/manage-job`, {
        params: {
          cursor: prevCursor,
          filter: dataNextFilter,
        },
        headers,
      });

      const jobData = response?.data?.result?.data || [];
      setJobs((prevJobs) => {
        const updatedJobs = [...prevJobs, ...jobData];
        return updatedJobs;
      });
      setJobData(jobData);
      setJobCursor(prevCursor);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching previous page data:", error);
    }
  };

  useEffect(() => {
    if (pageError) {
      handleError(pageError, "client", "getJobs");
    }
  }, [pageError]);

  return (
    <>
      <h3 className="manage-job__main_title border-bottom pb-3 mb-4">
        Manage Job
      </h3>
      <Form>
        <div style={{ minHeight: "690px" }}>
          <ManageJobFormTable
            jobNextData={jobNextDataList}
            jobs={visibleJobs}
            columnsPerPage={columnsPerPage}
            setSearchKeyword={setSearchKeyword}
            setJobs={setJobs}
            handleSearchSort={handleSearchAndSort}
            // loadTable=
            reloadData={reloadData}
            loadTable={isLoading}
            dataNextFilter={handleDataNextFilter}
            cursor={handleCursorData}
          />
        </div>
        <ManageJobFormPagination
          dataFromSelection={dataNextFilter}
          jobNextData={handleJobListData}
          jobData={jobData}
          jobs={jobs}
          columnsPerPage={columnsPerPage}
          count={jobCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          pageJobs={setJobs}
          cursorData={cursor}
          manageJobPaginationError={handleGetError}
          handleNextList={handleNextList}
          handlePrevList={handlePrevList}
          setCurrentPage={setCurrentPage}
        />
      </Form>
      <CustomModal
        show={modalShow}
        modalMessage={
          allError === "Error"
            ? "You have been automatically logged out"
            : "Something Went Wrong"
        }
        modalType={"Error"}
        onHide={() => setModalShow(false)}
        buttonName="Confirm"
        onClick={handleLogout}
      />
      {showModal && (
        <CustomModal
          hasOption={false}
          show={showModal}
          buttonName="OK"
          onHide={closeModal}
          modalMessage={modalMessage}
          modalType={messageType}
          onClick={closeModal}
          showLogoutButton={showLogoutButton}
          handleLogout={handleLogout}
        />
      )}
      <CustomModal
        closeButtonErrNetwork={modalInfo.ErrButton}
        secondButtonName={modalInfo.secondBName}
        show={modalInfo.show}
        modalType={modalInfo.type}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        hasOption={true}
        onHide={() => setModalInfo({ ...modalInfo, show: false })}
      />
    </>
  );
};

export default ManageJobForm;
